import { render, staticRenderFns } from "./AssetMovementSummaryReport.vue?vue&type=template&id=fb6c0e22&scoped=true&"
import script from "./AssetMovementSummaryReport.vue?vue&type=script&lang=js&"
export * from "./AssetMovementSummaryReport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb6c0e22",
  null
  
)

export default component.exports